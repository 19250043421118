import React from 'react';
import { Link } from 'gatsby';

import Grid from '../../Grid';
import IconCalendar from '../../../images/icon-calendar.png';

const BookingCancelConfirmation = ({ onConfirmCancellation, isSubmitting }) => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <img src={IconCalendar} alt="Icon Success" role="presentation" />
          <h1>Are you sure you want to cancel the booking?</h1>
          <p>
            We regret that you're considering canceling your booking. Please note that 20% of your booking amount is
            non-refundable. If you have any concerns, our support team is available to help you.
          </p>

          <div
            className="confirm-buttons-wrapper"
            style={{
              maxWidth: '610px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Link to="/tours" className="btn-md btn-outline confirm-btn">
              Back To Tours
            </Link>

            {isSubmitting ? (
              <button
                type="button"
                className="btn-md btn-outline btn-loading confirm-btn"
                disabled
                style={{
                  cursor: 'not-allowed',
                }}
              >
                <span className="spinner"></span> Confirm cancellation
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-md btn-primary-ii btn-submit confirm-btn"
                onClick={onConfirmCancellation}
              >
                Confirm cancellation
              </button>
            )}
          </div>

          <p className="copyright-text">© Lineage Journey {currentYear}.</p>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default BookingCancelConfirmation;
