import qs from 'qs';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import BodyClassName from 'react-body-classname';
import React, { useState, useEffect } from 'react';

import TourErrorModal from '../../components/Tour/TourBooking/TourErrorModal';
import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';
import BookingCancelSuccess from '../../components/Tour/TourBookingCancellation/BookingCancelSuccess';
import BookingCancelConfirmation from '../../components/Tour/TourBookingCancellation/BookingCancelConfirmation';

const ViewsEnum = {
  BOOKING_CANCEL_CONFIRMATION: 'Booking Cancel Confirmation',
  BOOKING_CANCEL_SUCCESS: 'Booking Cancel Success',
};

const TourCancellation = () => {
  const location = useLocation();

  const queryString = qs.parse(location.search.substring(1));
  const { token } = queryString;
  const { bookingId } = queryString;

  useEffect(() => {
    if (!token) {
      navigate('/tours');
    }
  }, [location]);

  const [currentView, setCurrentView] = useState(ViewsEnum.BOOKING_CANCEL_CONFIRMATION);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const onCancellationSuccess = () => {
    const nextView = Object.values(ViewsEnum)[Object.values(ViewsEnum).indexOf(currentView) + 1];
    setCurrentView(nextView);
  };

  const onCancellationFail = () => {
    setShowErrorModal(true);
  };

  const onConfirmCancellation = () => {
    setIsSubmitting(true);

    fetch(`${process.env.GATSBY_LINEAGE_BACKEND_URL}api/tour-bookings/${bookingId}/cancel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((body) => {
        if (body.success) {
          onCancellationSuccess();
        } else {
          onCancellationFail();
        }
      })
      .catch((err) => {
        console.error(err.message);
        onCancellationFail();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <BodyClassName className="body-dark">
      <DefaultLayoutComponent>
        <div className="site-main">
          <div className="wrapper wrapper-xl pd-x-md">
            <div className="bg-light dialog-inner booking-success">
              <div>
                {currentView === ViewsEnum.BOOKING_CANCEL_CONFIRMATION ? (
                  <BookingCancelConfirmation
                    onConfirmCancellation={onConfirmCancellation}
                    isSubmitting={isSubmitting}
                  />
                ) : (
                  <BookingCancelSuccess />
                )}
              </div>
            </div>

            <TourErrorModal open={showErrorModal} onClose={() => setShowErrorModal(false)} />
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default TourCancellation;
