import React from 'react';
import { Link } from 'gatsby';

import Grid from '../../Grid';
import IconCalendar from '../../../images/icon-calendar.png';

const BookingSuccess = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Grid>
        <Grid.Col md="2 / span 10" xs="1 / span 2">
          <img src={IconCalendar} alt="Icon Success" role="presentation" />
          <h1>Your tour booking has been canceled.</h1>
          <p>
            We are sorry to see that you have cancelled your upcoming tour with Lineage Journey. For more details about
            our cancellation policy and refund process, please refer to our terms and conditions. Contact our customer
            support team if you have any questions.
          </p>

          <div
            style={{
              maxWidth: '345px',
              margin: '32px auto 0 auto',
            }}
          >
            <Link to="/tours" className="btn btn-md btn-primary-ii btn-submit w-full">
              Back To Tours
            </Link>
          </div>

          <p className="copyright-text">© Lineage Journey {currentYear}.</p>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default BookingSuccess;
